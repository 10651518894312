@mixin page-heading-style {
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $Brand-Black;
}

@mixin global-accordion-header-container-style {
  padding: 0 40px;
  height: 80px;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;

  .order-accordian-heading-section {
    background: $Dark-Gray;
    border-radius: 200px;
    margin-right: 10px;
  }

  .global-accordian-header {
    .account-icon {
      margin: 5px 12px;
    }

    .order-status {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-right: 10px;
      color: #fff;
    }

    .order-accordian-heading {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
      // margin-right: 10px;
      // margin-left: 25px;
    }

    .order-ocasion-option,
    .order-receipt-option {
      cursor: pointer;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      color: $Brand-Black;
      margin-right: 20px;
      text-decoration-skip-ink: none;
    }
    .remove-from-fav {
      cursor: pointer;
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      color: $Brand-Black;
      margin-right: 20px;
      text-decoration-skip-ink: none;
    }
    .favoutite-unfavourite-img {
      height: 17px;
      margin-right: 5px;
    }
    .order-receipt-Disable-option {
      cursor: text;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      color: grey;
      margin-right: 20px;
    }
    .order-refund-msg {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Black;
      padding-right: 70px;
    }
  }

  .global-accordian-right {
    display: flex;
    align-items: center;
    cursor: pointer;

    .re-order-btn {
      display: flex;
      padding: 10px 35px;
      margin: 0 5px;

      img {
        margin-right: 5px;
      }
    }
  }

  .global-accordian-arrow {
    width: 10px;
    height: 10px;
  }
}

@mixin global-accordion-content-container {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: $Dark-Gray;
  background: $Mashed-Potato-Gray;
  box-shadow: 0 1px 0 $Light-Gray, 0 -1px 0 $Light-Gray;

  .account-accordian-details-section {
    .option-section {
      background: #f8f7f5;
      box-shadow: 0 1px 0 #e3e3e3, 0 -1px 0 #e3e3e3;

      &.option-section-fav-menu {
        display: flex;
        padding: 18px 36px;
        align-items: center;

        .favourite-order-img {
          width: 150px;
          height: 115px;
          border-radius: 5px;
        }
        .favourite-order-img-sopac {
          height: auto;
          border-radius: 5px;
        }

        @media #{$mobile-only} {
          padding: 16px 4px;
          align-items: flex-start;

          .favourite-order-img {
            width: 90px;
            height: auto;
          }

          .favourite-order-details {
            margin-left: 16px;
            font-size: 12px;
          }
        }
      }

      .favourite-order-details {
        display: flex;
        flex-direction: column;
        margin-left: 34px;
        font-size: 14px;
        padding-right: 24px;
        line-height: 25px;

        > span {
          &:first-child {
            font-weight: bold;

            @media #{$mobile-only} {
              font-size: 13px;
            }
          }
        }

        .occasion-item-price {
          font-weight: bold;
          margin-top: 5px;
        }

        .occasion-item-price-unavailable {
          font-size: 12px;
          color: #ff2e00;
          margin-top: 4px;

          img {
            width: 15px;
            height: 15px;
            margin-right: 4px;
          }
        }
      }
    }

    .occasion-fav-item {
      cursor: pointer;
      position: absolute;
      right: 5%;
    }

    .order-section {
      height: 152px;

      .product-details-section {
        flex-direction: column;
        display: flex;
        position: absolute;
        left: 30%;
        width: 60%;
        .product-title {
          font-family: $HelveticaNeue;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Brand-Black;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 5px 0;
        }

        .product-desc {
          font-family: $National2;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 5px 0;
          word-break: break-all;
          width: 80%;
        }

        .product-price {
          font-family: $National2;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Brand-Black;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 10px 0;
        }
      }
    }

    .order-item-img {
      height: 114.38px;
    }
  }
}

.account-desktop-container {
  display: flex;
  //padding-left: 104px;
  //padding-right: 130px;
  //margin-bottom: 5rem;

  .account-section-left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 40px 50px 70px;
    position: relative;
    width: 330px;
    height: 502px;
    // left: 0px;
    // top: 0px;
    background: $Brand-Black;
    border-radius: 6px;
    // width: 294px;

    .account-header-container {
      .account-header {
        top: 14.75%;
        position: absolute;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $white;
        overflow-wrap: anywhere;
        width: 67%;
        &.account-profile-name {
          width: auto;
        }
      }

      .account-user-name {
        top: 22.75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 67%;
      }

      .account-menu {
        margin: 7px 0;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $white;
        opacity: 0.5;
        cursor: pointer;
      }

      .activeMenu {
        opacity: 1;
        font-weight: bold;
      }

      .activeMenu::before {
        content: '';
        margin-top: 10px;
        left: -25px;
        position: absolute;
        width: 15px;
        color: white;

        /* height: 2px; */
        border: 1px solid white;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        display: inline-flex;
      }

      .account-menu-container {
        position: absolute;
        top: 38.6%;
        display: flex;
        flex-direction: column;

        .account-menu {
          display: flex;
        }
        .msg-badge {
          background: red;
          display: flex;
          border-radius: 20px;
          color: #fff;
          font-size: 10px;
          padding: 0 8px;
          margin-left: 10px;
          justify-content: center;
          align-items: center;
        }

        div {
          cursor: pointer;
        }
      }

      .sign-out-btn {
        align-self: center;
        left: 21.48%;
        position: absolute;
        bottom: 5%;

        @media #{$breakpoint-below-desktop-above-tablet} {
          left: 28%;
        }
      }
      .account-mobile-option {
        border: 2px solid #e3e3e3;
      }
    }
  }

  .account-section-right {
    margin-left: 75px;
    width: 780px;

    .page-header {
      display: flex;

      &.page-header-favourite {
        margin-top: 80px;
        margin-bottom: 10px;
      }
    }

    .no-occasion-container {
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 80px;
      p {
        font-size: 18px;
        line-height: 30px;
        color: #494949;
        letter-spacing: -0.1px;
        font-family: $National2Regular;
      }
    }

    .favorite-items-container {
      display: flex;
      flex-wrap: wrap;

      .medium-menu-product-image {
        position: static;
        float: right;
      }
    }

    .load-more-button-container {
      text-align: center;

      button {
        font-weight: bold;
      }

      .load-more-text {
        font-size: 14px;
        font-weight: 400;
        margin-top: 8px;
      }
    }

    .page-heading {
      @include page-heading-style;
    }

    .headingItemsRight {
      display: flex;
      position: absolute;
      right: 12%;
      width: auto;
      font-weight: 500;
    }

    .sub-heading-text {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .sub-section {
      margin-top: 50px;

      &.sub-section-favourite {
        margin-top: 10px;

        .global-accordian-header {
          .edit-occasion-link {
            cursor: pointer;
            font-family: $National2Medium;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            text-decoration: underline;
            margin-left: 12px;
            margin-top: 4px;
          }

          .occasion-accordian-header-global {
            display: flex;
            flex-direction: column;

            .edit-occasion-link {
              margin-left: 0;
            }
          }
        }

        .global-accordian-right {
          cursor: pointer;

          .order-time {
            font-family: $National2Regular;
          }
        }

        .account-accordian-open {
          & .edit-occasion-link {
            display: inline-flex;
          }
        }

        .account-accordian-closed {
          display: none;
        }
      }
    }

    .heading-item-text {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      margin-right: 28px;
    }

    .order-lookup {
      background: transparent;
      padding: 11px 25px;
      height: 26px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $Brand-Black;
      border: 1px solid;
    }

    .account-order-desktop {
      margin-bottom: 120px;

      .account-accordian {
        background: $Mashed-Potato-Gray;
        position: relative;
        // height: 80px;
        display: flex;
        align-items: center;
        margin-top: 10px;

        .track-your-order {
          background: transparent;
          padding: 11px 25px;
          height: 44px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          color: $Brand-Black;
          border: 1px solid;
          margin-right: 10px;
        }

        .order-time {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: right;
          color: $Dark-Gray;
          margin-right: 10px;
        }

        .global-accordian-header-container {
          @include global-accordion-header-container-style;
        }

        .global-accordian-content {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          background: $Mashed-Potato-Gray;
          box-shadow: 0 1px 0 $Light-Gray, 0 -1px 0 $Light-Gray;

          .account-accordian-details-section {
            .default-img {
              img {
                max-height: 80px;
              }
            }
            .option-section {
              background: #f8f7f5;
              box-shadow: 0 1px 0 #e3e3e3, 0 -1px 0 #e3e3e3;

              &.option-section-fav-menu {
                display: flex;
                padding: 18px 36px;
                align-items: center;
                .product-image-parent-container-fav-menu {
                  width: 144px;
                  height: 108px;
                  justify-content: center;
                  border-radius: 8px 8px 0 0;
                  overflow: hidden;
                  @media #{$breakpoint-above-tablet} {
                    margin-bottom: 15px;
                  }
                  @include backgroundImage();
                  img {
                    //to maintain aspect-ratio : 4/3 - start for img
                    top: 0 !important;
                    left: 0 !important;
                    bottom: 0 !important;
                    right: 0 !important;
                    position: absolute;
                    //to maintain aspect-ratio : 4/3 -end for img
                    margin: auto auto !important;
                    display: block;
                    transition: all 0.7s;

                    -webkit-transition: all 0.7s;
                    @include imageAnimation;
                    @media #{$breakpoint-above-destop-wide-large-screen} {
                      // width: 50% !important;
                    }
                    @media #{$breakpoint-above-tablet} {
                      &:hover {
                        -ms-transform: scale(1.1);
                        transform: scale(1.1);
                      }
                    }
                  }
                }
                .favourite-order-img {
                  width: 150px;
                  height: 115px;
                  border-radius: 5px;
                }
                .favourite-order-img-sopac {
                  height: auto;
                  border-radius: 5px;
                }

                @media #{$mobile-only} {
                  padding: 16px 4px;
                  align-items: flex-start;

                  .favourite-order-img {
                    width: 90px;
                    height: auto;
                  }

                  .favourite-order-details {
                    margin-left: 16px;
                    font-size: 12px;
                  }
                }
              }

              .favourite-order-details {
                display: flex;
                flex-direction: column;
                margin-left: 34px;
                font-size: 14px;
                padding-right: 24px;
                line-height: 25px;

                > span {
                  &:first-child {
                    font-weight: bold;

                    @media #{$mobile-only} {
                      font-size: 13px;
                    }
                  }
                }

                .occasion-item-price {
                  font-weight: bold;
                  margin-top: 5px;

                  .prev-price {
                    text-decoration: line-through;
                  }

                  .new-current-price {
                    color: #ff2e00;
                  }
                }

                .occasion-item-price-unavailable {
                  font-size: 12px;
                  color: #ff2e00;
                  margin-top: 4px;

                  img {
                    width: 15px;
                    height: 15px;
                    margin-right: 4px;
                  }
                }

                .fav-orders-item-name {
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: $Brand-Black;
                }

                .fav-item-price {
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: $Brand-Black;
                  margin: 10px 0;
                }
              }
            }

            .occasion-fav-item {
              cursor: pointer;
              position: absolute;
              right: 5%;
            }

            .order-section {
              height: 152px;

              .product-details-section {
                flex-direction: column;
                display: flex;
                position: absolute;
                left: 30%;
                width: 60%;

                .product-title {
                  font-family: $HelveticaNeue;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $Brand-Black;
                  flex: none;
                  order: 0;
                  flex-grow: 0;
                  margin: 5px 0;
                }

                .product-desc {
                  font-family: $National2;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: $Dark-Gray;
                  flex: none;
                  order: 1;
                  flex-grow: 0;
                  margin: 5px 0;
                  word-break: break-all;
                  width: 80%;
                }

                .product-price {
                  font-family: $National2;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $Brand-Black;
                  flex: none;
                  order: 1;
                  flex-grow: 0;
                  margin: 10px 0;
                }
              }
            }

            .order-item-img {
              height: 114.38px;
            }

            &.product-unavailable {
              .option-section-fav-menu {
                img {
                  opacity: 0.4;
                }
              }
            }
          }
        }
      }
    }

    .account-setting-text-heading,
    .account-message-text-heading {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: $Brand-Black;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .personal-info-card {
      background: $Mashed-Potato-Gray;
      width: 100%;
      height: fit-content;
      display: flex;

      .personal-card-left-side {
        width: 25.77%;
        color: $Brand-Black;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        padding: 40px 40px 80px 31px;
      }

      .personal-card-right-side {
        width: 74.23%;
        padding: 35px 40px 30px 0;

        p {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Dark-Gray;
          margin: 0;
          padding: 0;
        }

        .edit-input {
          float: right;
          text-decoration: underline;

          a {
            color: $Brand-Black;
          }
        }
      }
    }

    .preferences-card {
      margin-top: 10px;
      background: $Mashed-Potato-Gray;
      width: 100%;
      height: fit-content;
      display: flex;

      .preferences-card-left-side {
        width: 25.77%;
        word-wrap: break-word;
        color: $Brand-Black;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        padding: 40px 0 80px 31px;
      }

      .preferences-card-right-side {
        width: 74.23%;
        padding: 35px 40px 30px 0;

        p {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Dark-Gray;
          margin: 0;
          padding: 0;
        }

        .edit-input {
          float: right;
          text-decoration: underline;

          a {
            color: $Brand-Black;
          }
        }

        .horizontal-line-club {
          margin-top: 28px;
          border-top: 1px solid #dbdbdb;
          padding-bottom: 10px;
        }

        .preferences-container {
          margin-top: 5px;

          .preferences-item {
            list-style: none;
            color: $Brand-Black;
            padding: 0;
            margin: 0;
            cursor: pointer;
          }

          .preferences-item-list {
            display: flex;
            align-items: center;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            // letter-spacing: -0.1px;
            margin-top: 10px;

            label {
              margin: 0;
              padding: 0;
              cursor: pointer;
            }
          }

          .preferences-text {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            margin-top: -2px;
          }

          .preference-subText {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
          }

          input {
            align-self: flex-start;
          }

          input[type='checkbox'] {
            // margin: 0 4% 0 5%;
            appearance: none;
            height: 16px;
            width: 16px;
            min-width: 16px;
            background: $Brand-Black;
            border-radius: none;
            cursor: pointer;
            outline: none;
            border: 1px solid $Light-Gray;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          input[type='checkbox']::after {
            content: '✓';
            color: white;
            font-weight: 900;
            font-size: 12px;
            display: none;
          }

          input[type='checkbox']:checked {
            background-color: $Brand-Black;
          }

          input[type='checkbox']:checked::after {
            display: block;
          }
        }

        .save-account-button {
          color: $white;
          margin-top: 26px;
          border: 1.5px solid #202124;
          box-sizing: border-box;
          border-radius: 200px;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          padding: 0.625em 4.65em;
          background: $Brand-Black;
        }
      }
    }

    .personal-information-modal {
      .modal-content {
        width: 45%;
        left: 30%;

        .modal-header {
          .header-title {
            margin: 0 0 0 2%;
            font-size: 18px;
          }
        }

        .modal-body {
          background: none;
        }
      }

      .personal-information-card {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        padding: 25px 30px;

        .personal-information-text {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
        }

        .edit-info-input-class {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          background: $Mashed-Potato-Gray;
        }

        .buttons-container {
          display: flex;
          justify-content: center;
          flex-direction: row;

          .buttons-group {
            border: 1.5px solid $Brand-Black;
            box-sizing: border-box;
            border-radius: 200px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;

            &:disabled {
              background: rgba(219, 219, 219, 0.3);
              color: $Brand-Black;
              border: none;
              cursor: not-allowed;
            }
          }

          .cancel {
            color: $Brand-Black;
            background: none;
            margin-right: 8px;
            padding: 10px 35px;
          }

          .save {
            color: white;
            background: $Brand-Black;
            margin-left: 8px;
            padding: 10px 40px;
          }
        }
      }
    }

    .delete-account-button {
      margin: 24px 0 100px 0;
      border: 1.5px solid $Brand-Black;
      box-sizing: border-box;
      border-radius: 200px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 0.625em 2.5em;
      background: none;
    }

    .pageFooter {
      margin: 60px 0;

      .load-more,
      .view-order-count {
        justify-content: center;
        display: flex;
      }

      .footer-btn {
        background: $white;
        display: flex;
        color: $Brand-Black;
        border: 1px solid $Brand-Black;
      }

      .view-order-count {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        margin-top: 5px;
        margin-bottom: 50px;
      }

      .get-help-section {
        display: flex;
        justify-content: space-between;
      }

      .horizontal-bar {
        background: $Gray-light;
        opacity: 0.5;
        margin-bottom: 30px;
      }

      .footer-bootm-left {
        display: flex;
        flex-direction: column;
      }

      .have-a-question {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
      }

      .connect-specialist {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        margin-top: 5px;
      }
    }

    .no-order {
      display: flex;
      flex-direction: column;
    }

    .no-order-msg {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      opacity: 0.8;
      margin-bottom: 20px;
    }

    .view-menu-btn {
      background: #202124;
      border-radius: 200px;
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 25px;
    }
  }

  //Remove from favourite orders Modal css
  #removeFromFavOrders {
    background-color: $gray-background;
    .modal-content {
      width: 38%;
      height: auto;
      left: 30%;
      top: 25%;
      @media #{$breakpoint-medium-desktop} {
        top: 32% !important;
        left: 32% !important;
      }
      @media #{$breakpoint-above-medium-desktop} {
        top: 34% !important;
      }
      .modal-body {
        background-color: $white;
      }
    }

    .modal-header {
      border-bottom: none;
      height: 40px;
    }
    .remove-from-favorite-orders-modal {
      text-align: center;
      padding: 10px 80px 40px 80px;

      .remove-message-section {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
      }

      .closebtn-cls {
        margin-right: 15px;
      }
    }
  }
}

.account-mobile-container {
  .account-mobile-header {
    margin-bottom: 21px;
    padding-right: 20px;
    padding-left: 20px;

    .account-icon {
      margin-bottom: 20px;
      width: 12% !important;
    }

    .account-header {
      margin-bottom: 20px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .account-user-name-mobile {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .sign-out-btn {
      border: 1px solid #202124;
      color: #202124;
    }
  }

  .account-mobile-menu-option {
    margin-bottom: 60px;

    .account-accordian {
      padding: 6px 20px;
      border-top: 2px solid $Light-Gray;

      .global-accordian-header-container {
        .global-accordian-header {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: $Brand-Black;
        }
        .msg-badge {
          background: red;
          display: flex;
          border-radius: 20px;
          color: #fff;
          font-size: 14px;
          margin-left: 10px;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 20%;
          min-width: 25px;
          height: 25px;
        }
      }

      .global-accordian-content {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
      }
    }

    .account-accordian:last-child {
      border-bottom: 2px solid $Light-Gray;
    }

    .account-accordian-open {
      background-color: $Mashed-Potato-Gray;
    }
  }
  .sign-out-btn-au {
    border: 1px solid #202124;
    color: #202124;
  }
}

.mobile-order-history {
  .order-history-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #dbdbdb;
    opacity: 0.7;
    margin-bottom: 30px;
  }
  .back-icon {
    transform: rotateY(180deg);
    position: absolute;
    left: 15px;
    top: 15px;
  }

  .ReceiptModal {
    padding: 30px 20px;
  }

  .your-order {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 1px;
  }

  .receipt-number {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
  }

  .receipt-sub-heading {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
  }

  .width-style {
    width: 40%;
  }

  .width-style-lg {
    width: 84%;
  }

  .contact-info {
    flex-direction: column;
  }

  .page-header {
    display: flex;
    padding: 0 20px;
  }

  .no-order {
    display: flex;
    flex-direction: column;
  }

  .no-order-msg {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.1px;
    color: $Dark-Gray;
    opacity: 0.8;
    margin-bottom: 20px;
  }

  .page-heading {
    @include page-heading-style;
    font-size: 20px;
    letter-spacing: 1px;
  }

  .headingItemsRight {
    display: flex;
    position: absolute;
    right: 6%;
    font-weight: 500;
  }

  .sub-heading-text {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .sub-section {
    margin-top: 30px;

    &.sub-section-favourite {
      .favorite-container-global {
        background: $Mashed-Potato-Gray;

        &.account-accordian-open {
          .occasion-accordian-header-global {
            .edit-occasion-link {
              display: flex;
              margin-left: 20px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .sub-section-no-order {
    margin-top: 30px;
    padding-left: 21px;
    padding-right: 59px;
    .sub-heading-text {
      margin-left: 0px;
    }
  }

  .view-menu-btn {
    background: #202124;
    border-radius: 200px;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 25px;
  }

  .heading-item-text {
    display: none;
  }

  .order-lookup {
    padding: 11px 5px;
    height: 25px;
    background: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid;
    color: $Brand-Black;
  }

  .account-accordian {
    position: relative;
    // height: 80px;
    display: flex;
    align-items: center;

    .track-your-order {
      background: transparent;
      padding: 11px 3px;
      height: 44px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $Brand-Black;
      margin-right: 10px;
      border: 1px solid;
    }

    .order-time {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: right;
      color: $Dark-Gray;
      margin-right: 10px;
    }

    .global-accordian-header-container {
      padding-right: 30px;
      align-items: center;
      height: 80px;
      position: relative;

      .order-accordian-heading-section {
        background: $Dark-Gray;
        border-radius: 200px;
        margin-left: 10px;
        height: 26px;
        width: 26px;
      }

      .global-accordian-header {
        .account-icon {
          margin: 0 5px;
        }

        .order-status {
          display: none;
        }

        .order-accordian-heading {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-left: 20px;
        }

        .edit-occasion-link {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-decoration-line: underline;
          color: #494949;
          margin-left: 12px;
        }

        .occasion-accordian-header-global {
          .edit-occasion-link {
            display: none;
          }
        }

        .order-ocasion-option,
        .order-receipt-option {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: block;
          align-items: center;
          text-decoration-line: underline;
          color: $Brand-Black;
          margin-right: 20px;
          text-decoration-skip-ink: none;
        }

        .remove-from-fav {
          margin-top: -23px;
        }

        .favoutite-unfavourite-img {
          height: 17px;
          margin-right: 5px;
        }
        .order-refund-msg {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Brand-Black;
        }
      }

      .global-accordian-right {
        display: flex;
        cursor: pointer;
        align-items: center;
      }

      .global-accordian-arrow {
        width: 10px;
        height: 10px;
      }
    }

    .global-accordian-content {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      background: $Mashed-Potato-Gray;
      box-shadow: 0 1px 0 $Light-Gray, 0 -1px 0 $Light-Gray;

      .account-accordian-details-section {
        .option-section {
          background: $Mashed-Potato-Gray;
          box-shadow: 0 1px 0 $Light-Gray, 0 -1px 0 $Light-Gray;
          position: relative;
        }
        .option-section-fav-menu {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: $Mashed-Potato-Gray;
          box-shadow: 0 1px 0 $Light-Gray, 0 -1px 0 $Light-Gray;
          position: relative;
          padding-top: 18px;
          padding-left: 18px;
          padding-bottom: 24px;
          .product-image-parent-container-fav-menu {
            width: 100px;
            height: 75px;
            justify-content: center;
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            @media #{$breakpoint-above-tablet} {
              margin-bottom: 15px;
            }
            @include backgroundImage();
            img {
              //to maintain aspect-ratio : 4/3 - start for img
              top: 0 !important;
              left: 0 !important;
              bottom: 0 !important;
              right: 0 !important;
              position: absolute;
              //to maintain aspect-ratio : 4/3 -end for img
              margin: auto auto !important;
              display: block;
              transition: all 0.7s;

              -webkit-transition: all 0.7s;
              @include imageAnimation;
              @media #{$breakpoint-above-destop-wide-large-screen} {
                // width: 50% !important;
              }
              @media #{$breakpoint-above-tablet} {
                &:hover {
                  -ms-transform: scale(1.1);
                  transform: scale(1.1);
                }
              }
            }
          }
          .favourite-order-img {
            width: 104px;
            height: 79px;
          }
          .favourite-order-img-sopac {
            height: auto;
          }

          .favourite-order-details {
            display: flex;
            width: 52.5%;
            flex-direction: column;

            .order-item-name {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #202124;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 2px 0px;
            }

            .fav-order-details > span {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 22px;
              color: $Dark-Gray;
            }

            .occasion-item-price {
              font-family: $National2Medium;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              font-style: normal;

              .prev-price {
                text-decoration: line-through;
              }
              .new-current-price {
                color: #ff2e00;
              }
              .current-price {
                text-align: right;
                color: #202124;
              }
            }

            .occasion-item-price-unavailable {
              font-family: $National2Medium;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 22px;
              color: #ff2e00;

              img {
                width: 15px;
                height: 15px;
              }
            }

            .fav-orders-item-name {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $Brand-Black;
            }

            .fav-item-price {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $Brand-Black;
              margin: 2px 0;
            }
          }

          .fav-order-details-global {
            width: 64%;
            padding-right: 10px;
          }

          .occasion-fav-item {
            margin-right: 5%;
          }
        }

        .order-section {
          height: 148px;

          .mobile-order-details-section {
            padding: 18px 18px 51px 18px;
          }

          .product-details-section {
            display: flex;
            flex-direction: column;
            width: 80%;
            align-items: start;
            // position: absolute;
            left: 37%;
            margin-bottom: auto;
            margin-top: 18px;

            .product-title {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: $Brand-Black;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 2px 0;
            }

            .product-desc {
              font-family: $National2;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 22px;
              color: $Dark-Gray;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 2px 0;
              word-break: break-all;
              width: 80%;
              max-height: 3.6em;
              overflow: hidden;
            }

            .product-price {
              font-family: $National2;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              text-align: right;
              color: $Brand-Black;
              flex: none;
              order: 2;
              flex-grow: 0;
              margin: 2px 0;
            }
          }

          .global-accordian-right {
            position: absolute;
            right: 6%;
            top: 14.8%;
            cursor: pointer;
          }
        }

        .order-item-img {
          height: 79px;
        }

        &.product-unavailable {
          .option-section {
            .favourite-order-img {
              width: 104px;
              height: 79px;
              opacity: 0.4;
              margin-right: 2%;
            }
            .favourite-order-img-sopac {
              height: auto;
              opacity: 0.4;
              margin-right: 2%;
            }
          }
        }
      }

      .fav-menu-event {
        .mobile-accordian-reorder-container {
          flex-direction: column-reverse;
          padding-top: 17px;
          padding-right: 0;
          height: 142px;
          justify-content: center;
          align-items: center;

          .global-accordian-right {
            display: flex;
            padding: 19px 20px 20px 20px;
            cursor: pointer;

            @media (max-width: 350px) {
              width: 100%;
            }
          }

          .mobile-accordian-header {
            display: flex;
            justify-content: center;
          }

          .re-order-btn {
            width: 335px;
          }
        }
      }
    }

    .account-accordian-open {
      background-color: $Mashed-Potato-Gray;

      .mobile-accordian-header {
        height: 194px;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        padding-left: 20px;
      }

      .mobile-accordian-reorder-container {
        flex-direction: column;
        padding-top: 17px;
        padding-right: 0;
        height: 142px;

        .global-accordian-right {
          padding: 25px 20px 20px 20px;
          cursor: pointer;

          @media (max-width: 350px) {
            width: 100%;
          }
        }
        .re-order-btn {
          width: 335px;
        }
        .btn-algn .re-order-btn {
          width: unset;
          padding: 11px 30px;
          margin: 0 5px;
          @media (max-width: 350px) {
            padding: 11px 20px;
          }
        }
      }

      .mobile-refund-section {
        height: 194px;
        padding: 29px 21px 39px 20px;
        flex-direction: column;

        .global-accordian-right {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .pageFooter {
    margin: 48px 0;

    .load-more,
    .view-order-count {
      justify-content: center;
      display: flex;
    }

    .footer-btn {
      background: $white;
      display: flex;
      color: $Brand-Black;
      border: 1px solid $Brand-Black;
    }

    .view-order-count {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      margin-top: 5px;
      margin-bottom: 50px;
    }

    .get-help-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .horizontal-bar {
      background: $Gray-light;
      opacity: 0.5;
      margin-bottom: 30px;
    }

    .footer-bootm-left {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .have-a-question {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
      margin-bottom: 5px;
    }

    .connect-specialist {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      margin-bottom: 30px;
    }
  }

  #removeFromFavOrders {
    .modal-content {
      width: 100%;
      height: 33%;
      left: 0;
      top: 67%;
      border-radius: 10px 10px 0 0;
      .modal-body {
        background-color: $white;
      }
    }

    @media #{$breakpoint-iphone-se-2022} {
      .modal-content {
        top: 60% !important;
        height: 40% !important;
      }
    }

    .modal-header {
      border-bottom: none;
      height: 40px;
    }
    .remove-from-favorite-orders-modal {
      text-align: center;

      .remove-message-section {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        padding-left: 6%;
        padding-right: 6%;
      }

      .closebtn-cls {
        margin-right: 15px;
      }
    }
  }

  .default-img {
    img {
      max-height: 80px;
    }
  }
}

#favourite-orders-global {
  .sub-section {
    @media #{$breakpoint-below-tablet} {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .shared-modal-container {
      @media #{$breakpoint-below-tablet} {
        background-color: #2021241c;
      }
    }
  }

  .page-header-favourite-occasion {
    margin-bottom: 25px;
  }
  #occassion-modal {
    background-color: $gray-background;
    .modal-content {
      @media #{$breakpoint-medium-desktop} {
        top: 29% !important;
        left: 30% !important;
      }
      @media #{$breakpoint-above-tablet} {
        top: 29%;
        left: 30%;
        height: 313px;
      }
      @media #{$breakpoint-below-tablet} {
        top: 55%;
        height: 390px;
        width: 100%;
        left: 0;
        border-radius: 10px 10px 0 0;
      }
      .add-occassion-global {
        @media #{$breakpoint-above-tablet} {
          padding: 6px 104px 52px;
        }
      }
    }
  }
}

.mobile-favourite-order {
  padding: 20px;

  .page-header-favourite-occasion,
  .page-header-favourite {
    .page-heading {
      font-family: $National2Condensed;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  .page-header-favourite {
    margin-top: 67px;
  }

  .sub-section-favourite {
    .account-accordian {
      background: $Mashed-Potato-Gray;
      position: relative;
      // height: 80px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      .global-accordian-header-container {
        @include global-accordion-header-container-style;

        @media #{$mobile-only} {
          padding: 0 10px;

          .order-accordian-heading {
            font-size: 16px;
          }
        }
      }

      .global-accordian-content {
        @include global-accordion-content-container;
      }

      .global-accordian-header {
        .edit-occasion-link {
          cursor: pointer;
          font-family: $National2Regular;
          font-size: 12px;
          display: none;
          text-decoration: underline;
          margin-left: 12px;
          margin-top: 4px;
        }
      }

      .global-accordian-right {
        cursor: pointer;

        .order-time {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: right;
          color: $Dark-Gray;
          margin-right: 10px;
        }
      }

      .account-accordian-open {
        & .edit-occasion-link {
          display: inline-flex;
        }
      }

      .mobile-accordian-reorder-container {
        @media #{$mobile-only} {
          flex-direction: column;
          height: auto;

          .mobile-accordian-header {
            order: 1;
            width: 100%;
            justify-content: center;
            margin-top: 13px;
            margin-bottom: 17px;

            .order-receipt-option {
              margin-right: 0;
            }
          }

          .global-accordian-right {
            width: 100%;
            margin-top: 46px;
            cursor: pointer;

            .re-order-btn {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .no-occasion-container {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 50px;
  }

  .favorite-items-container {
    display: flex;
    flex-wrap: wrap;

    .medium-menu-product-image {
      position: static;
      float: right;
    }
  }

  .load-more-button-container {
    text-align: center;
    font-size: 14px;
  }
}

// Account Setting for mobile UI
@media #{$breakpoint-below-tablet} {
  .account-setting-text-heading,
  .account-message-text-heading {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: $Brand-Black;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-left: 21px;
  }

  .personal-info-card {
    background: $Mashed-Potato-Gray;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    .personal-card-left-side {
      color: $Brand-Black;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: 24px;
      margin-left: 20px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 20px;
    }

    .personal-card-right-side {
      // width: 74.23%;
      margin: 18px 20px 23px 20px;

      p {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $Dark-Gray;
        margin: 0;
        padding: 0;
      }

      .edit-input {
        float: right;
        text-decoration: underline;

        a {
          color: $Brand-Black;
        }
      }
    }
  }

  .preferences-card {
    margin-top: 5px;
    background: $Mashed-Potato-Gray;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    .preferences-card-left-side {
      color: $Brand-Black;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: 24px;
      margin-left: 20px;
      margin-bottom: 18px;
    }

    .preferences-card-right-side {
      padding: 0 21px;

      p {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $Dark-Gray;
        margin: 0;
        padding: 0;
      }

      .edit-input {
        margin-top: -41px;
        float: right;
        text-decoration: underline;

        a {
          color: $Brand-Black;
        }
      }

      .horizontal-line-club {
        margin-top: 23px;
        margin-bottom: 23px;
        border-top: 1px solid #dbdbdb;
      }

      .preferences-container {
        margin-top: 5px;

        .preferences-item {
          list-style: none;
          color: $Brand-Black;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }

        .preferences-item-list {
          display: flex;
          align-items: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          // letter-spacing: -0.1px;
          margin-top: 10px;

          label {
            margin: 0;
            padding: 0;
            cursor: pointer;
          }
        }

        .preferences-text {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          margin-top: -2px;
        }

        .preference-subText {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: $Dark-Gray;
        }

        input {
          align-self: flex-start;
        }

        input[type='checkbox'] {
          // margin: 0 4% 0 5%;
          appearance: none;
          height: 16px;
          width: 16px;
          min-width: 16px;
          background: $Brand-Black;
          border-radius: none;
          cursor: pointer;
          outline: none;
          border: 1px solid $Light-Gray;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input[type='checkbox']::after {
          content: '✓';
          color: white;
          font-weight: 900;
          font-size: 12px;
          display: none;
        }

        input[type='checkbox']:checked {
          background-color: $Brand-Black;
        }

        input[type='checkbox']:checked::after {
          display: block;
        }
      }

      .save-account-button {
        color: $white;
        margin-top: 40px;
        margin-bottom: 27px;
        border: 1.5px solid #202124;
        box-sizing: border-box;
        border-radius: 200px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0.625em 4.65em;
        background: $Brand-Black;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .personal-information-modal {
    .modal-content {
      width: 100%;

      .modal-header {
        .header-title {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: $Dark-Gray;
          text-transform: capitalize;
        }

        margin-bottom: 30px;
      }

      .modal-body {
        background: none;
        padding: 0;
        margin: 0;
      }
    }

    .personal-information-card {
      background: $Mashed-Potato-Gray;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      padding: 30px 20px;

      .personal-information-text {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
      }

      .edit-info-input-class {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        background: $Mashed-Potato-Gray;
      }

      .buttons-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-top: 35px;

        .buttons-group {
          border: 1.5px solid $Brand-Black;
          box-sizing: border-box;
          border-radius: 200px;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;

          &:disabled {
            background: rgba(219, 219, 219, 0.3);
            color: $Brand-Black;
            border: none;
            cursor: not-allowed;
          }
        }

        .cancel {
          color: $Brand-Black;
          background: none;
          margin-right: 8px;
          padding: 10px 35px;
        }

        .save {
          color: white;
          background: $Brand-Black;
          margin-left: 8px;
          padding: 10px 40px;
        }
      }
    }
  }

  .delete-account-button {
    margin-top: 23px;
    margin-bottom: 48px;
    margin-left: 50%;
    transform: translateX(-50%);
    border: 1.5px solid $Brand-Black;
    box-sizing: border-box;
    border-radius: 200px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0.625em 2.5em;
    background: none;
  }
}

// Account Settings mobile UI SCSS END.
.confirm-delete-account-modal {
  .modal-content {
    width: 50%;
    height: 40vh;
    left: 0;
    right: 0;
    margin-left: 50%;
    transform: translate(-50%, 50%);

    @media #{$breakpoint-below-tablet} {
      width: 85%;
      height: 30vh;
      left: 0;
      right: 0;
      margin-left: 50%;
      transform: translate(-50%, 50%);
    }

    .modal-header {
      border: none;
      padding: 5% 1%;
    }

    .modal-body {
      background: none;
      padding: 0;
      margin: 0;

      .confirm-delete-account-content {
        border-radius: 3px;
        width: 100%;
        height: 100%;
        padding: 0 20px 30px 20px;

        .main-heading {
          text-align: center;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
        }

        .delete-account-text {
          text-align: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Brand-Black;
        }

        .buttons-container {
          display: flex;
          justify-content: center;
          flex-direction: row;
          margin-top: 35px;

          .buttons-group {
            border: 1.5px solid $Brand-Black;
            box-sizing: border-box;
            border-radius: 200px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;

            &:disabled {
              background: rgba(219, 219, 219, 0.3);
              color: $Brand-Black;
              border: none;
              cursor: not-allowed;
            }
          }

          .cancel {
            color: $Brand-Black;
            background: none;
            margin-right: 8px;
            padding: 10px 35px;
          }

          .save {
            color: white;
            background: $Brand-Black;
            margin-left: 8px;
            padding: 10px 40px;
          }
        }
      }
    }
  }
}

//***** Account Modal styles starts here *****//

.account-desktop-container,
.mobile-order-history {
  .account-order-desktop {
    @media #{$breakpoint-below-tablet} {
      .no-occasion-container {
        padding: 0 20px;
        margin-top: 18px;
        p {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: -0.1px;
          color: #494949;
        }

        .browse-menu-btn {
          margin-left: 0;
        }
      }

      .page-header-favourite {
        margin-top: 50px;
      }

      .page-header-favourite-occasion {
        margin-top: 30px;
      }
    }
  }
}
.favorites-added-toast {
  width: 240px;
  margin: auto;
  @media #{$breakpoint-below-tablet} {
    margin: auto;
    top: 60% !important;
  }
  .img-text-div img {
    left: 7.5%;
  }
}

.Toastify {
  & .Toastify__toast-container {
    top: unset !important;
  }
}

.Toastify__toast-container {
  bottom: 20% !important;
  @media #{$mobile-only} {
    bottom: 4% !important;
  }
  @media #{$breakpoint-above-large-desktop} {
    bottom: 35% !important;
  }
}

.Occassion-modal,
.Mobile-occassion-modal {
  .modal-content {
    height: 337px;

    .modal-header {
      border-bottom: none;

      .custom-modal-close {
        top: 5%;
        z-index: 1;
      }
    }

    .modal-body {
      background: $white;
      box-shadow: none;
    }
  }

  .OccassionModal {
    padding: 35px 80px 52px 80px;

    @media #{$mobile-only} {
      padding: 35px 20px 52px 20px;
    }

    .InputHeading {
      font-family: $National2Condensed;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
    }
  }

  .occasion-input-box {
    width: 379px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: $Brand-Black;
  }

  .occasion-input-box::placeholder {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
  }

  .inputLookUpNumbox {
    margin-top: 40px;

    .error-text {
      display: block;
      padding-top: 6px;
      color: #cc2400;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .add-occassion-global {
    padding-top: 17px;
    form {
      label {
        display: inline-block;
        transition: all 0.2s ease;
        z-index: 10;
        position: relative;
        top: -30px;
        font-weight: 400;
      }

      .inputLookUpNumbox {
        position: relative;
        margin-top: 49px;

        .error-text {
          color: $Red-Orange;
        }
        input {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #202124;
        }

        .error-input {
          color: $Red-Orange;
        }

        .error-text-container {
          display: flex;
          &.special-char-error {
            img {
              position: relative;
              top: 3px;
            }
          }
          &.name-exist-error {
            img {
              position: relative;
              bottom: 2px;
            }
          }
        }

        input[type='text'] {
          width: 100%;
          position: relative;
          display: inline-block;
          padding: 0 0 5px 0;
          background-color: transparent;
          border: none;
          border-bottom: solid 1.5px #202124;
          outline: none;
          z-index: 20;
          font-weight: 500;
        }

        input[type='text']:focus ~ label,
        input[type='text']:valid ~ label {
          top: -55px;
          font-size: 12px;
          line-height: 22px;
          color: #494949;
        }
      }
    }

    .clearTextIcon {
      position: absolute;
      top: 7%;
      right: 0;
      cursor: pointer;
      z-index: 99;
    }
    .buttonItems {
      margin-top: 25px;

      button {
        width: 129px;
        @media #{$mobile-only} {
          width: 180px;
        }
      }

      .closebtn-cls {
        margin-right: 15px;
      }
    }
  }
}

.Mobile-occassion-modal {
  .modal-content {
    height: 301px;
    top: 50%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .modal-body {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .OccassionModal {
    padding: 0;
    padding-bottom: 60px;
  }

  .occasion-input-box {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: $Brand-Black;
  }

  .occasion-input-box::placeholder {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
  }
}

//***** Account Modal styles ends here *****//

// **** account page mobile header for navigating back style starts here ****//
.order-history-header-mobile,
.my-delivery-addresses-header-mobile,
.account-settings-header-mobile,
.account-message-header-mobile,
.my-payment-methods-header-mobile {
  display: none;

  @media #{$breakpoint-below-tablet} {
    display: flex;
    width: 100%;
    height: 46px;
    background: $white;
    border-bottom: 1px solid $Inactive-Gray;
    justify-content: center;
    align-items: center;

    .backArrow-account-setting {
      position: absolute;
      left: 5%;
      width: 16px;
      height: 16px;
    }
    .order-history-text-header,
    .my-delivery-addresses-text-header,
    .account-setting-text-header,
    .account-message-text-header {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }
  }
}

.reorder-modal-global {
  .reorder-item-section {
    .reorder-accordian {
      .global-accordian-container {
        .global-accordian-header-container {
          @media #{$breakpoint-below-tablet} {
            margin: 0 !important;
          }
        }
      }
    }
  }
}

.addr-container {
  .modal-content {
    top: 40% !important;
    @media #{$breakpoint-samsung-galaxy-tab-s7} {
      top: 62% !important;
    }
  }

  .alert-title {
    padding: 10px;
    width: 60%;
    @media #{$breakpoint-below-tablet} {
      width: 85%;
    }
  }
}

.mobile-accordian-reorder-container {
  @media #{$breakpoint-ipad-pro-tablet} {
    padding: 0 15px !important;
  }
}

//**Account settings global css sart**//
.personal-info-section {
  margin-top: 5px;
}

.personal-data-view {
  display: flex;
  margin-bottom: 17px;
  flex-direction: row;
  div:nth-child(1) {
    width: 110px;
    font-family: $National2Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
  div:nth-child(2) {
    font-family: $National2Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
  }
}

.edit-info-link {
  a {
    text-decoration: underline;
    text-transform: capitalize;
    color: $Brand-Black;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    font-family: $National2Medium;
  }
}

@media #{$breakpoint-above-tablet} {
  .preferences-card-left-side-au {
    padding: 40px 0 40px 31px !important;
  }
}
@media #{$breakpoint-ipad-12-pro-portrait} {
  .preferences-card-left-side-au {
    width: 30% !important;
    margin-right: 10px;
  }
}
.preferences-item-list-au {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a {
    text-decoration: underline;
    color: $Brand-Black;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: right;
    font-family: $National2Medium;
  }
  a:hover {
    color: $Brand-Black;
  }
  .preferences-text {
    margin-left: unset !important;
  }
}

@media #{$breakpoint-below-tablet} {
  .preferences-item-list-au {
    padding-bottom: 40px;
  }
}

//**Account settings global css end**//

//** Edit Personal Info global css starts**//
.edit-personal-data-view {
  display: flex;
  flex-direction: row;
  // padding:2px;
  .material-input {
    position: relative;
  }
  .material-input.field input {
    background-color: transparent;
    padding-bottom: 5px;
    margin-left: 5px;
    font-family: $National2Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    z-index: 2;
  }
  .left-side-lables {
    width: 110px;
    font-family: $National2Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    @media #{$breakpoint-below-tablet} {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-left: 4px;
      font-size: 10px;
    }
  }
  .right-side-form {
    width: 100%;
    font-family: $National2Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  @media #{$breakpoint-below-tablet} {
    display: unset;
    margin-bottom: 5px;
  }
  .email-border {
    .material-input.field input {
      border-bottom: 0 !important;
    }
  }
  .error-text {
    color: #ff2e00;
    padding-left: 4px;
    img {
      margin-right: 5px;
    }
  }
  .tooltip-view {
    position: absolute;
    height: 15px;
    // bottom: 12px;
    right: 0;
    z-index: 9;
  }
}

.global-buttons-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
  .buttons-group {
    border: 1.5px solid $Brand-Black;
    box-sizing: border-box;
    border-radius: 200px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    // &:disabled {
    // 	background: rgba(219, 219, 219, 0.3);
    // 	color: $Brand-Black;
    // 	border: none;
    // 	cursor: not-allowed;
    // }
  }
  .cancel {
    color: $Brand-Black;
    background: none;
    margin-left: 8px;
    padding: 10px 40px;
    // width: 129px;
    font-family: $National2Regular;
  }
  .save {
    color: white;
    background: $Brand-Black;
    margin-left: 8px;
    padding: 10px 45px;
    // width: 129px;
    font-family: $National2Regular;
  }
  .save-disabled {
    color: $Brand-Black;
    background: #dbdbdb;
    margin-left: 8px;
    padding: 10px 45px;
    // width: 129px;
    font-family: $National2Regular;
    border: 1.5px solid #dbdbdb;
  }
}

.delete-account-modal {
  .modal-content {
    .modal-header .custom-modal-close {
      @media screen and (max-width: 420px) {
        margin: 5px;
        margin-top: 0;
      }
    }
    height: auto;
    @media #{$breakpoint-below-tablet} {
      height: 340px;
    }
    @media #{$breakpoint-below-desktop-above-tablet} {
      width: 50%;
      left: 25%;
    }
    .modal-body {
      @media #{$breakpoint-below-include-iphone5} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .alert-title {
    width: 250px;
    padding: 0 1%;
    // margin-top: 20px;
    @media #{$breakpoint-below-tablet} {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      padding: 0;
    }
    .alert-red-text {
      color: $Brand-Red;
    }
  }
  .alert-action-container {
    margin-top: 30px !important;
    button {
      margin: 0;
      //width: 138px !important;
    }
  }
}

/*css for floating select*/

.dis-flex {
  display: flex;
  position: relative;
  margin: 10px 0px 0px 5px;
  justify-content: space-between;
  .tool-tip-desktop {
    margin-top: -10px;
  }
}

.set-margin {
  margin: 0 10px;
}

.input-container1 {
  // width: 41.5%;
  padding-right: 20px;
  width: 120px;
  @media #{$breakpoint-between-mob-tablet} {
    width: 220px;
  }
  @media #{$mobile-only} {
    width: 110px;
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    width: 25%;
    padding-right: 5px;
  }
}
.input-container2 {
  // width: 41.5%;
  padding-right: 20px;
  width: 120px;
  @media #{$breakpoint-between-mob-tablet} {
    width: 220px;
  }
  @media #{$mobile-only} {
    width: 110px;
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    width: 55%;
    padding-left: 10px !important;
    padding-right: 0px !important;
  }
}

.input-container3 {
  // /* width: 113px; */
  width: 130px;
  padding-right: 0px !important;
  @media #{$breakpoint-between-mob-tablet} {
    width: 220px;
  }
  @media #{$mobile-only} {
    width: 110px;
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    width: 30%;
    padding-left: 10px !important;
  }
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}
.did-floating-label1 {
  font-weight: 400;
  font-family: $National2Regular;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #202124;
}

.did-floating-label {
  font-weight: 400;
  font-family: $National2Regular;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #202124;
  position: absolute;
  pointer-events: none;
  top: 10px;
  background: transparent;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-select {
  font-weight: 400 !important;
  font-family: $National2Medium;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #202124;
  display: block;
  width: 100%;
  height: 36px;
  padding: 8px 0px 0 0px;
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #202124;
  box-sizing: border-box;
}
.error-color {
  color: #ff2e00;
}
.error-color-disable {  
  color: #202124;
}
.did-floating-select:focus {
  outline: none;
}

.dropdown-notfocus {
  background-image: url('../../../../src/styles/staticAssets/images/Accordian_Arrow.png') !important;
}

.dropdown-focus {
  background-image: url('../../../../src/styles/staticAssets/images/Accordian_Up_Arrow.png') !important;

  @media #{$breakpoint-below-tablet} {
    background-image: url('../../../../src/styles/staticAssets/images/Accordian_Arrow.png') !important;
  }
  
}
// .did-floating-select:focus~.did-floating-label {
// 	top: -8px;
// 	font-size: 12px;
// 	line-height: 22px;
// 	color: #494949;
// }

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* padding: 30px; */
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-select:valid ~ .did-floating-label {
  top: -10px;
  font-size: 12px;
  line-height: 22px;
  color: #494949;
}

/* .did-floating-select[value=""]:focus ~ .did-floating-label {
	top:11px;
	font-size:13px;
} */

.did-floating-select:not([multiple]):not([size]) {
  background-image: url('../../../../src/styles/staticAssets/images/Accordian_Arrow.png');
  background-position: right 5px top 58%;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

select option {
  background: #202124;
  margin: 10px;
  color: #fff;
  font-family: $National2Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

option:hover {
  background: #202124de;
}

select::-webkit-scrollbar {
  width: 3px;
}

select::-webkit-scrollbar-track {
  background: #494949;
  margin-top: 10px;
  margin-bottom: 10px;
}

select::-webkit-scrollbar-thumb {
  background: white;
}

.personal-info-card-au {
  .material-input.field input:placeholder-shown + label {
    margin-left: 4px !important;
    font-size: 10px;
  }
  .material-input.field input:not(:placeholder-shown) + label {
    margin-left: 4px !important;
    font-size: 10px;
  }
  .material-input.field {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.dob-error-show {
  color: #ff2e00;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  img {
    margin-right: 5px;
  }
}
.dob-present {
  font-family: $National2Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  margin-left: -15px;
  @media #{$breakpoint-below-tablet} {
    margin-left: unset;
  }
}

.account-header-container-au {
  .account-header {
    @media #{$breakpoint-below-desktop-above-tablet} {
      font-size: 18px !important;
    }
  }
}
.flex-div {
  display: flex;
  flex-direction: row;
}

.verification-faild-container {
  display: flex;
  background: #fff4f2;
  padding: 10px;
  margin-bottom: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #202124;
  font-family: $National2Regular;

  img {
    margin-right: 5px;
  }
}
//** Edit Personal Info global css Ends **//

@media #{$breakpoint-ipad-pro-11-oreintation-landscape} {
  .account-gloabal-container {
    .account-section-left {
      width: 46%;
      padding: 0 20px 50px 70px !important;
      .account-header-container .account-header {
        letter-spacing: 0.6px !important;
      }
      .account-user-name {
        letter-spacing: 0.6px !important;
      }
    }
  }
}
.account-gloabal-container {
  .account-section-left .account-header-container .account-header {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -30px;
  }
  .account-section-left .account-header-container .account-user-name {
    position: absolute;
    top: 22.75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // width: 100%;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    margin-top: 0;
  }
  .account-section-left .account-header-noon {
    .account-header {
      width: 55%;
      white-space: normal;
    }
    .account-user-name {
      top: 30.75%;
    }
    .account-menu-container {
      top: 42.6%;
    }
    .sign-out-btn {
      bottom: 5%;
    }
  }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1400px) {
  .account-gloabal-container {
    .account-section-left {
      padding: 0 20px 50px 70px;
    }
  }
}

@media #{$breakpoint-desktop-small} {
  .account-gloabal-container {
    .account-section-left {
      // padding: 0 20px 50px 40px;
      .account-header-container .account-header {
        font-size: 22px !important;
        letter-spacing: 0.6px !important;
      }
      .account-user-name {
        font-size: 22px !important;
        letter-spacing: 0.6px !important;
      }
      .account-header-noon {
        .account-header {
          width: 50% !important;
          white-space: normal;
        }
        .account-user-name {
          top: 30.75%;
        }
        .account-menu-container {
          top: 42.6%;
        }
        .sign-out-btn {
          bottom: 10.5%;
        }
      }
    }
  }
}

@media #{$breakpoint-samsung-galaxy-s7-tab} {
  .account-gloabal-container {
    .account-section-left {
      padding: 0 20px 50px 60px !important;
      .account-header-container .account-header {
        font-size: 22px !important;
        letter-spacing: 0.6px !important;
      }
      .account-user-name {
        font-size: 22px !important;
        letter-spacing: 0.6px !important;
      }
      .account-header-noon {
        .account-header {
          width: 50% !important;
          white-space: normal;
        }
        .account-user-name {
          top: 30.75%;
        }
        .account-menu-container {
          top: 42.6%;
        }
        .sign-out-btn {
          bottom: 10.5%;
        }
      }
    }
  }
}
.delete-account-button-au {
  font-family: $National2Medium !important;
  color: $Brand-Black;
}

.account-section-right-au {
  @media #{$breakpoint-ipad-12-pro-portrait} {
    width: 500px !important;
  }
  .payment-methods{
    .payment-methods-heading{
      font-size: 24px !important;
      letter-spacing: 0.5px !important;
    }
  }

}

.card-deleted-toast {
  width: 140px;
  margin: auto;
  font-weight: bold;
  @media #{$breakpoint-below-tablet} {
    margin: auto;
    top: 60% !important;
  }
  .img-text-div img {
    left: 7.5%;
  }
}
